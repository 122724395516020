/* global js_app */
/* global geojson_data */
import mapboxgl from 'mapbox-gl';

class MapboxWrapper {
  constructor() {
    mapboxgl.accessToken = js_app.mapbox_token;

    this.map = null;
    this.defaultMarker = null;

    this.initMap();
  }

  /**
   * Initialise the map
   */
  initMap() {
    this.map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/streets-v12',
      center: [1.4366, 44.4491],
      zoom: 5,
    });

    // Create a default marker (single page)
    if (js_app.mapbox_default_marker_position) {
      // Update marker position with committee coordinates
      this.updateMarkerPosition(js_app.mapbox_default_marker_position[0], js_app.mapbox_default_marker_position[1]);
    } else if (typeof geojson_data !== 'undefined') {
      // Create markers from WP query
      this.updateMarkersFromFeatures(geojson_data);
    }
  }

  // Update marker position : remove current market and add the new one
  updateMarkerPosition(lng, lat) {
    // Remove current marker
    if (this.defaultMarker) {
      this.defaultMarker.remove();
    }

    // Resize map
    this.map.resize();

    // Add new marker
    this.defaultMarker = new mapboxgl.Marker({ color: '#0E1F5A'})
    .setLngLat([lng, lat])
    .addTo(this.map);

    // Center map to marker
    this.map.flyTo({
      center: [lng, lat],
      zoom: 13,
    });
  }

  updateMarkersFromFeatures(geojson_data) {
    for (const feature of geojson_data.features) {
      // Add new marker
      new mapboxgl.Marker({ color: '#0E1F5A'})
      .setLngLat(feature.geometry.coordinates)
      .setPopup(
        new mapboxgl.Popup({ offset: 25 }) // add popups
        .setHTML(
          `<a href="${feature.properties.url}" class="card mapcard-program">
              <div class="mapcard-img">
                ${feature.properties.logo}
                <span class="mapcard-fakelink">
                  <span class="mapcard-fakelink-icon"></span>
                </span>
              </div>
              <p class="mapcard-title">${feature.properties.name}</p>
              <p class="mapcard-address">${feature.properties.address}</p>
          </a>`
        )
      )
      .addTo(this.map);
    }

    if (geojson_data.features.length > 1) {
      // Calculer les bornes pour englober tous les points
      const coordinates = geojson_data.features.map(feature => feature.geometry.coordinates);
      const bounds = coordinates.reduce(
        (bounds, coord) => bounds.extend(coord),
        new mapboxgl.LngLatBounds(coordinates[0], coordinates[0])
      );

      this.map.fitBounds(bounds, {
          padding: 30,
      });
    } else if (typeof geojson_data.features[0] !== 'undefined') {
      // Centrer la carte sur le marqueur
      this.map.flyTo({
        center: geojson_data.features[0].geometry.coordinates,
        zoom: 13,
      });
    }
  }
}

let mapboxWrapper = null;

document.addEventListener('DOMContentLoaded', function() {
  const map = document.querySelector('#map');

  if (!map) {
    return;
  }

  mapboxWrapper = new MapboxWrapper();
});

export { mapboxWrapper };
