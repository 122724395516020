const $document = $(document);
const $body = $('body');

$(function () {
  const next = $('#form-button-next');
  const submit = $('#form-button-submit');

  let currentSection = 1;
  showSection(currentSection);

  // Event listeners
  $(document).on('click', '#form-button-next', function (e) {
    e.preventDefault();

    if (currentSection < 4) {
      currentSection++;
      showSection(currentSection);
    }
  });

  function removeActiveStep() {
    $('.ad-form-active-step').removeClass('ad-form-active-step');
  }

  function setActiveStep(step) {
    $('#active-step-' + step).addClass('ad-form-active-step');
  }

  function showSection(section) {
    switch (section) {
      case 1:
        removeActiveStep();
        setActiveStep(1);
        submit.hide();
        break;

      case 2:
        removeActiveStep();
        setActiveStep(2);
        submit.show();
        next.hide();
        break;

      case 3:
        removeActiveStep();
        setActiveStep(3);
        submit.hide();
        next.hide();
        break;

      default:
        break;
    }

    $('.form-section').addClass('ad-form-hidden');
    $('.ad-form-subtitle').addClass('ad-form-hidden');

    $('#section-' + section).removeClass('ad-form-hidden');
    $('#subtitle-' + section).removeClass('ad-form-hidden');
  }

  $document.on("submit", "#ad-form-create", function (event) {
    event.preventDefault();

    const additional_images = $("#additional_images");
    if (parseInt(additional_images.get(0).files.length) > 2) {
      alert("You can only upload a maximum of 2 files");
      return;
    }

    const $form = $(this);
    $.ajax({
      url: $form.data('href'),
      type: "POST",
      data: new FormData(this),
      processData: false, // Nécessaire pour éviter la transformation des données
      contentType: false, // Nécessaire pour laisser le navigateur gérer le type MIME
      beforeSend: function () {
        $body.addClass("is-loading");
      },
      success: function () {
        currentSection++;
        showSection(currentSection);
      },
      error: function () {
        $('#ad-form-error-message').removeClass('d-none');
      },
      complete: function () {
        $body.removeClass("is-loading");
      },
    });
  });

  $(window).keydown(function (event) {
    if ((event.keyCode == 13)) {
      event.preventDefault();
      return false;
    }
  });
});
