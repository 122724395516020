import { mapboxWrapper } from './mapbox.js';

$(document).ready(function(){
  const body = $('body');
  if (body.hasClass('post-type-archive-ligue') || body.hasClass('post-type-archive-comite')) {
    $(document).on("change", ".select-item-toggler", function (e) {
      $('.ligue-selected').removeClass('is-active');
      if (e.target.value != '*') {
        $('.ligue-selected[data-id=' + e.target.value + ']').addClass('is-active');
        // Get coordinates from current committee
        const selected_committee = $('#comiter-selector option[value="' + e.target.value + '"]');
        if (selected_committee.data('lng') !== '' &&  selected_committee.data('lat') !== '') {
          // Display map
          $('.map-section').removeClass('d-none');
          // Update marker position with committee coordinates
          mapboxWrapper.updateMarkerPosition(selected_committee.data('lng'), selected_committee.data('lat'));
        } else {
          $('.map-section').addClass('d-none');
        }
      } else {
        $('.map-section').addClass('d-none');
      }
    });
  }
});
